<template>
  <section class="content">
    <div class="box box-primary" style="padding: 10px; position: relative">
      <div id="root">
        <form
          name="adminForm"
          method="post"
          enctype="multipart/form-data"
          @submit="submit"
        >
          <input
            name="cassielAction"
            value="save"
            type="hidden"
            id="cassielActionInput"
          />
          <input
            id="cassielId"
            name="cassielId"
            :value="cassielid"
            type="hidden"
          />
          <div class="form-group">
            <label class="text-monospace text-bold text-capitalize"
              >Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              id="name"
              name="name"
              class="form-control"
              placeholder="Insert Name Here"
              :value="displayName"
              required
            />
          </div>
          <div class="form-group">
            <cassielFieldsRender
              :index="0"
              :formConfiguration="widgetsObject"
              :field="widgetsObject"
              @valueChanged="onChange"
            />
          </div>
          <div class="form-group">
            <cassielFieldsRender
              :index="0"
              :formConfiguration="widgetsSubObject"
              :field="widgetsSubObject"
              @valueChanged="onComponentChange"
            />
          </div>
          <div class="form-group">
            <label class="text-monospace text-bold text-capitalize"
              >Template <span class="text-danger">*</span></label
            >
            <input
              type="text"
              id="template"
              class="form-control"
              name="template"
              :value="template"
              placeholder="Insert Template Here"
              required
            />
          </div>
          <div class="form-group">
            <label class="text-monospace text-bold text-capitalize"
              >Preview Field
            </label>
            <input
              type="text"
              id="previewField"
              name="previewField"
              class="form-control"
              placeholder="Insert Preview Field Here"
              :value="previewField"
            />
          </div>

          <adminFormBuilder
            ref="adminFormBuilder"
            v-bind:params="params"
          ></adminFormBuilder>
          <button
            name="cassielActionBtn"
            id="cassielAction"
            value="save"
            class="btn btn-primary"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
<style>
.required::after {
  content: "*";
  color: red;
}
</style>

<script>
import cassielFieldsRender from "@/views/edit/Component/cassielFieldsRender";
// import draggable from 'vuedraggable'
import adminFormBuilder from "@/views/custom/adminFormBuilder";
export default {
  name: "cassielembeds",
  mixins: [],
  data: function () {
    return {
      cassielid:
        this.$route.query.cassielId != undefined
          ? this.$route.query.cassielId
          : "",
      name: "",
      params: [],
      displayName: "",
      template: "",
      previewField: "",
      widget: "",
      widgets: [],
      widgetsObject: {
        type: "dropdown",
        label: "Widget",
        multiple: false,
        name: "widget",
        placeholder: "Select",
        required: true,
        value: "",
        options: [
          {
            id: "",
            section: "Select",
            text: "Select",
          },
        ],
      },
      widgetsSubObject: {
        type: "dropdown",
        label: "Component",
        multiple: false,
        name: "component",
        placeholder: "Select",
        required: true,
        value: "",
        options: [
          {
            id: "",
            section: "Select",
            text: "Select",
          },
        ],
      },
      component: "",
      list: [],
      templates: [],
    };
  },
  components: {
    // draggable,
    adminFormBuilder,
    cassielFieldsRender,
  },
  created() {},
  methods: {
    onChange(value) {
      this.widget = value;
      this.name = "";
      this.widgetsSubObject.options = [];
      this.widgets[value].map((item) => {
        this.widgetsSubObject.options.push({
          id: item.component,
          section: item.component,
          text: item.component,
        });
      });
    },
    onComponentChange(event) {
      this.name = event;
    },
    async submit(event) {
      if (event != undefined) {
        event.preventDefault();
      }
      await this.$refs.adminFormBuilder.getGeneratedTemplate(event);
      let data = this.$refs.adminFormBuilder.data;
      let formData = new FormData(event.target);
      formData.append("html", data);

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      this.$http({
        url: process.env.VUE_APP_CMS_BACKEND_BASE_URL + "/submit/core/embeds",
        method: "POST",
        data: formData,
        headers: headers,
      }).then(() => {
        let button = event.submitter;
        button.innerText = "Saved!";
        button.style.backgroundColor = "green";

        setTimeout(function () {
          button.innerText = "Save";
          button.style.removeProperty("background-color");
        }, 2000);
      });
      // axios({
      //   method: 'post',
      //   url: '/submit/core/embeds',
      //   data: formData,
      //   headers: {'Content-Type': 'multipart/form-data'}
      // }).then((response) => {
      //   let button = event.submitter;
      //   button.innerText = 'Saved!'
      //   button.style.backgroundColor = 'green'
      //
      //   setTimeout(function(){
      //     button.innerText = 'Save';
      //     button.style.removeProperty("background-color");
      //   }, 2000);
      // });
    },
    removeDataOnCLick: function (section, index) {
      this.$delete(this.data[section], index, "");
    },
  },
  mounted: function () {
    var bodyFormData = new FormData();
    bodyFormData.append("cassielId", this.cassielid);
    const token = this.$store.getters["auth/getToken"];
    let headers = {};
    if (token) {
      headers["X-AUTH-TOKEN"] = token;
    }
    this.$http({
      url:
        process.env.VUE_APP_CMS_BACKEND_BASE_URL +
        "/formconfiguration/core/embeds",
      method: "POST",
      data: bodyFormData,
      headers: headers,
    }).then((response) => {
      this.widgetsObject.value = response.data.configuration.widget;
      for (var key in response.data.configuration.widgets) {
        this.widgetsObject.options.push({
          id: key,
          section: key,
          text: key,
        });
      }

      this.widgetsSubObject.value = response.data.configuration.name;
      response.data.configuration.widgets[
        response.data.configuration.widget
      ].map((item) => {
        this.widgetsSubObject.options.push({
          id: item.component,
          section: item.component,
          text: item.component,
        });
      });

      this.widget = response.data.configuration.widget;
      this.widgets = response.data.configuration.widgets;
      this.component = response.data.configuration.name;
      this.name = response.data.configuration.name;
      this.displayName = response.data.configuration.displayName;
      this.template = response.data.configuration.template;
      this.previewField = response.data.configuration.previewField;
      // let params = response.data.configuration.params;
      this.params = JSON.parse(response.data.configuration.params, true);
    });
  },
};
</script>

<style>
.form-control {
  height: 100%;
}

label {
  margin-top: 0.5rem;
}

.cassiel-dropplace div span {
  color: teal;
  font-size: 18px;
  font-weight: bold;
}
.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #212121;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-bottom: 3px solid teal;
}

.fieldstemplates div {
  font-size: 24px;
  margin-bottom: 10px;
}

.fieldstemplates .grid {
  display: flex;
  width: 100%;
}

.fieldstemplates .grid input {
  margin: auto;
  margin-right: 10px;
}

.fieldstemplates div div {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

#cassiel-library div div div input {
  display: none;
}

#cassiel-library div div div label {
  display: none;
}

#cassiel-library div div div h1 {
  font-size: 24px;
  margin: 0px;
}

span {
  cursor: pointer;
}

.field-type {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  border: 1px dashed;
}

.field-type:active {
  box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px;
}

.field-type i.fa {
  padding-right: 20px;
}

.inputList {
  /* min-height: 200px; */
  /* border: 1px dashed; */
  /* padding: 5px; */
}

.inputList-item {
  background: #ecf0f5;
  /* padding: 10px; */
  margin-bottom: 10px;
}

.inputList-header {
  position: relative;
  text-transform: capitalize;
  color: #fff;
  background: #586bb2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 16px 19px;
}

.inputList-header h3 {
  margin-top: 0;
  font-size: 25px;
  font-weight: 500;
}

.inputList-header i.fa {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.inputList-header i.fa-close {
  right: 10px;
}

.inputList-header i.fa-minus {
  right: 35px;
}

.inputList-header i.fa-arrows {
  right: 55px;
}
.inputList-body {
  padding: 10px 22px;
}
.inputList-item hr {
  border-top: 1px solid #fff;
}

.box-custom {
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
</style>
