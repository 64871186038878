<template id="croppieType">
  <div
    class="field-type"
    v-on:click="addInput('croppieType')"
    name="croppieType"
  >
    <i class="fa fa-cut"></i> Croppie type
  </div>
</template>
<script>
export default {
  name: "croppieType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="croppieType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]" pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "<i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Width</label>' +
        '   <input type="text" type="number" min="0" step="10" name="inputs[{index}][width]" placeholder="Width" class="form-control w-100" value="{width}" required/>' +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Height</label>' +
        '   <input type="number" min="0" step="10" name="inputs[{index}][height]" placeholder="Height" class="form-control w-100" value="{height}" required/>' +
        "</div>",
      html: "",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "croppieType", this.template);
    this.$emit("addgeneratedtemplate", "croppieType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
