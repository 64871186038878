<template id="textType">
  <div class="field-type" v-on:click="addInput('textType')" name="textType">
    <i class="fa fa-pencil"></i> Text type
  </div>
</template>

<script>
export default {
  name: "textType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="textType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]"  pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "<i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Label</label>' +
        '   <input type="text" name="inputs[{index}][label]" placeholder="Label" class="form-control w-100" value="{label}" required/>' +
        "</div>" +
        '<div class="checkbox">' +
        '   <label><input type="checkbox" name="inputs[{index}][required]" value="checked" {required}/> Required</label>' +
        "</div>" +
        "<hr>" +
        '<div class="form-group">' +
        "   <label>Group</label>" +
        '   <input type="text" name="inputs[{index}][group]" placeholder="Group" class="form-control w-100" value="{group}">' +
        "</div>",
      html:
        "" +
        '<div class="form-group">' +
        "   <label>{label}</label>" +
        '   <input type="text" name="{name}" class="form-control" placeholder="{label}" {required}>' +
        "</div>",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "textType", this.template);
    this.$emit("addgeneratedtemplate", "textType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
