<template id="groupType">
  <div></div>
</template>

<script>
export default {
  name: "groupType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template: '',
      html: '<div id="_KEY_" class="groupList" style="border: 1px solid #eee;border-radius: 10px;padding: 10px;">{html}</div>' +
          '<button class="btn btn-primary" style="margin-top: 10px;float: right;" onclick="addRow_KEY_(this)">Add Row</button>' +
          '<script>' +
          '   function addRow_KEY_(elem){ ' +
          '       let div = \'{addedRow}\';' +
          '       elem.previousSibling.insertAdjacentHTML(\'beforeend\', div);' +
          '   }' +
          '   function removeRow_KEY_(elem){' +
          '       if(elem.parentElement.parentElement.childElementCount === 1){' +
          '        return;' +
          '       }' +
          '       elem.parentElement.remove();' +
          '   }' +
          '<\\/script>',
    }
  },
  mounted: function () {
    this.$emit('addtemplate', 'groupType', this.template);
    this.$emit('addgeneratedtemplate', 'groupType', this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v)
      this.$emit('addinput', k);
    },
  },
};
</script>