<template id="checkboxType">
  <div
    class="field-type"
    v-on:click="addInput('checkboxType')"
    name="checkboxType"
  >
    <i class="fa fa-check-square"></i> Checkbox type
  </div>
</template>

<script>
export default {
  name: "checkboxType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="checkboxType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]"  pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "<i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Label</label>' +
        '   <input type="text" name="inputs[{index}][label]" placeholder="Label" class="form-control w-100" value="{label}" required/>' +
        "</div>" +
        "<hr>" +
        '<div class="form-group">' +
        "   <label>Group</label>" +
        '   <input type="text" name="inputs[{index}][group]" placeholder="Group" class="form-control w-100" value="{group}">' +
        "</div>",
      html:
        "" +
        '<div class="checkbox">' +
        '   <label><input type="checkbox" name="{name}" placeholder="{label}" {required}> {label}</label>' +
        "</div>",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "checkboxType", this.template);
    this.$emit("addgeneratedtemplate", "checkboxType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
