<template id="multiselectType">
  <div
    class="field-type"
    v-on:click="addInput('multiselectType')"
    name="multiselectType"
  >
    <i class="fa fa-caret-square-o-down"></i> Multiselect type
  </div>
</template>
<script>
export default {
  name: "multiselectType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="multiselectType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]"  pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "   <i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Label</label>' +
        '   <input type="text" name="inputs[{index}][label]" placeholder="Label" class="form-control w-100" value="{label}" required/>' +
        "</div>" +
        '<div class="form-group">' +
        "   <label>Display field</label>" +
        '   <input type="text" name="inputs[{index}][displayField]" placeholder="Display field" class="form-control w-100" value="{displayField}"/>' +
        "</div>" +
        '<div class="form-group">' +
        "   <label>Value field</label>" +
        '   <input type="text" name="inputs[{index}][valueField]" placeholder="Value field" class="form-control w-100" value="{valueField}"/>' +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">API link</label>' +
        '   <input type="text" name="inputs[{index}][api]" placeholder="API link" class="form-control w-100" value="{process.env.VUE_APP_CMS_BACKEND_BASE_URL}{api}" required/>' +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Max selection</label>' +
        '   <input type="number" min="1" name="inputs[{index}][max]" placeholder="Max selection" class="form-control w-100" value="{max}" required/>' +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Data URL params</label>' +
        '   <textarea name="inputs[{index}][params]" placeholder="Data URL params" class="form-control  w-100" style="height: 100px">{params}</textarea>' +
        '   <i>JSON array only, for example: [{"name": "NAME", "input": "INPUT", "value": "VALUE"}, ...]</i>' +
        "</div>" +
        '<div class="form-group">' +
        "   <label>Added Scripts</label>" +
        '   <textarea name="inputs[{index}][scripts]" placeholder="Added Scripts" class="form-control w-100" ' +
        '       style="height: 300px;font-family: monospace;font-size: 12px;background: #0E2231; color: #fff">' +
        "{scripts}" +
        "</textarea>" +
        "</div>",
      html: "",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "multiselectType", this.template);
    this.$emit("addgeneratedtemplate", "multiselectType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
