<template id="galleryType">
  <div
    class="field-type"
    v-on:click="addInput('galleryType')"
    name="galleryType"
  >
    <i class="fa fa-clone"></i> Gallery type
  </div>
</template>
<script>
export default {
  name: "galleryType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="galleryType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]" pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "<i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        "   <label>Width</label>" +
        '   <input type="number" min="0" step="10" name="inputs[{index}][width]" placeholder="Width" class="form-control w-100" value="{width}"/>' +
        "</div>" +
        '<div class="form-group">' +
        "   <label>Height</label>" +
        '   <input type="number" min="0" step="10" name="inputs[{index}][height]" placeholder="Height" class="form-control w-100" value="{height}"/>' +
        "</div>",
      html: "",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "galleryType", this.template);
    this.$emit("addgeneratedtemplate", "galleryType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
