<template id="adminFormBuilder">
  <div>
    <div style="display: none">{{params}}</div>
  <baseForm
      ref="baseForm"
      v-on:click="addInput($event)"
      v-on:getgeneratedtemplate="getGeneratedTemplate">
  </baseForm>
  </div>
</template>
<script>
// import draggable from 'vuedraggable'
import baseForm from "@/views/custom/FormBuilderTemplates/baseForm";

export default {
  name: "adminFormBuilder",
  mixins: [],
  props: {
    params: {
      type: [Array, Object]
    },
  },
  data: function () {
    return {
      data: "",
    }
  },
  components: {
    baseForm
  },
  computed: {},
  beforeUpdate: function () {
    baseForm.items = this.params;
    this.$nextTick(function () {
       this.params.forEach((item, index) => {
       this.$refs.baseForm.addInput(item.key, item)
        console.log(index)
      });
    })
  },
  methods: {
    async getGeneratedTemplate(event) {
      this.data = "";

      let formData = new FormData(event.target);
      let items = Object.fromEntries(formData);
      let htmls = this.$refs.baseForm.htmls;
      let groups = {};

      let i = 0;
      while (items['inputs[' + i + '][key]'] !== undefined) {
        let key = items['inputs[' + i + '][key]'];
        let html = htmls[key];
        if (key === 'croppieType') {
          html = await this.handleCroppieType(items, i);
        } else if (key === 'galleryType') {
          html = await this.handleGalleryType(items, i);
        } else if (key === 'multiselectType') {
          html = await this.handleMultiselectType(items, i);
        } else if (key === 'radioType') {
          html = await this.handleRadioType(items, i, html);
        } else {
          html = this.handleOtherTypes(items, i, html);
        }

        if (items['inputs[' + i + '][group]'] !== undefined && items['inputs[' + i + '][group]'].length > 0) {
          let group = items['inputs[' + i + '][group]'];

          if (groups[group] === undefined) groups[group] = '';
          groups[group] += html;

        } else {
          this.data += html;
        }
        i++;
      }

      for (var key in groups) {
        let html = htmls['groupType'];
        let div = '' +
            '<div class="groupItem" style="position: relative;">' +
            '   <h4 style="margin: 0px 0 15px;color: #3c8dbc;">Item</h4>' +
            '   <i class="fa fa-close" style="position: absolute;right: 0;top: 5px;cursor: pointer" ' +
            '       onclick="removeRow' + key + '(this)"></i>' +
            groups[key] +
            '</div>';
        div = div.replace(new RegExp('(\\r\\n|\\n|\\r)', 'g'), "");
        div = div.replace(new RegExp('[ ]+', 'g'), " ");
        div = div.trim()

        let addedRow = div.replace(new RegExp("<\\/script>", 'g'), "<\\/script>");
        addedRow = addedRow.replace(new RegExp('\'', 'g'), "\\'");

        html = html.replace(new RegExp('{html}', 'g'), div);
        html = html.replace(new RegExp('_KEY_', 'g'), key);
        html = html.replace(new RegExp('{addedRow}', 'g'), addedRow);
        this.data += html;
      }
      return this.data;
      // console.log(this.data)
    },
    async handleCroppieType(items, i) {
      let html = '';
      let name = items['inputs[' + i + '][name]'];
      let width = items['inputs[' + i + '][width]'];
      let height = items['inputs[' + i + '][height]'];

      var data = new FormData();
      data.append('widget', 'froalaextra');
      data.append('name', name);
      data.append('width', width);
      data.append('height', height);
      data.append('domain', process.env.VUE_APP_CMS_BACKEND_BASE_URL);
      const token = this.$store.getters['auth/getToken'];
      let headers = {};
      if (token) {
        headers['X-AUTH-TOKEN'] = token;
      }
      await this.$http({
        'url': process.env.VUE_APP_CMS_BACKEND_BASE_URL + '/cassiel-backend-core/vuejs/getcassielcroppie',
        'method': 'POST',
        'data': data,
        'headers': headers
      }).then(response => {
        html = response.data.html;
      });
      return html;
    },
    async handleGalleryType(items, i) {
      let html = '';
      let name = items['inputs[' + i + '][name]'];
      let width = items['inputs[' + i + '][width]'];
      let height = items['inputs[' + i + '][height]'];

      var data = new FormData();
      data.append('widget', 'froalaextra');
      data.append('name', name);
      data.append('width', width);
      data.append('height', height);
      data.append('domain', process.env.VUE_APP_CMS_BACKEND_BASE_URL);
      const token = this.$store.getters['auth/getToken'];
      let headers = {};
      if (token) {
        headers['X-AUTH-TOKEN'] = token;
      }
      await this.$http({
        'url': process.env.VUE_APP_CMS_BACKEND_BASE_URL + '/cassiel-backend-core/vuejs/getgallery',
        'method': 'POST',
        'data': data,
        'headers': headers
      }).then(response => {
        html = response.data.html;
      });
      return html;
    },
    async handleMultiselectType(items, i) {
      let html = '';
      let name = items['inputs[' + i + '][name]'];
      let label = items['inputs[' + i + '][label]'];
      let displayField = items['inputs[' + i + '][displayField]'];
      let valueField = items['inputs[' + i + '][valueField]'];
      let api = process.env.VUE_APP_CMS_BACKEND_BASE_URL + items['inputs[' + i + '][api]'];
      let max = items['inputs[' + i + '][max]'];
      let params = items['inputs[' + i + '][params]'];
      let scripts = items['inputs[' + i + '][scripts]'];

      const token = this.$store.getters['auth/getToken'];
      let headers = {};
      if (token) {
        headers['X-AUTH-TOKEN'] = token;
      }

      var data = new FormData();
      data.append('name', name);
      data.append('label', label);
      data.append('displayField', displayField);
      data.append('valueField', valueField);
      data.append('api', api);
      data.append('max', max);
      data.append('params', params);
      data.append('scripts', scripts);
      data.append('domain', process.env.VUE_APP_CMS_BACKEND_BASE_URL);

      await this.$http({
        'url': process.env.VUE_APP_CMS_BACKEND_BASE_URL + '/cassiel-backend-core/vuejs/getmuliselect',
        'method': 'POST',
        'data': data,
        'headers': headers
      }).then(response => {
        html = response.data.html;
      });
      return html;
    },
    handleRadioType(items, i, html) {

      let result = "";

      let name = items['inputs[' + i + '][name]'];

      let labels = [];
      if (items['inputs[' + i + '][labels]'] !== undefined) {
        labels = items['inputs[' + i + '][labels]'].split("|");
      }

      let values = [];
      if (items['inputs[' + i + '][values]'] !== undefined) {
        values = items['inputs[' + i + '][values]'].split("|");
      }
      let $ = require("jquery");

      $.each(labels, function(index) {
        let value = labels[index];
        if(values[index] !== undefined){
          value = values[index];
        }
        result += html.replace(new RegExp('{label}', 'g'), labels[index])
            .replace(new RegExp('{name}', 'g'), name)
            .replace(new RegExp('{value}', 'g'), value)
      });

      return result;
    },
    handleOtherTypes(items, i, html) {
      let name = items['inputs[' + i + '][name]'];

      if (items['inputs[' + i + '][group]'] !== undefined && items['inputs[' + i + '][group]'].length > 0) {
        name += '[]';
      }

      let label = "";
      if (items['inputs[' + i + '][label]'] !== undefined) {
        label = items['inputs[' + i + '][label]'];
      }

      let required = "";
      if (items['inputs[' + i + '][required]'] == 1) {
        required = "required";
      }

      let input = "";
      if (items['inputs[' + i + '][' + name + ']'] !== undefined) {
        input = items['inputs[' + i + '][' + name + ']'];
      }


      html = html.replace(new RegExp('{label}', 'g'), label)
          .replace(new RegExp('{name}', 'g'), name)
          .replace(new RegExp('{required}', 'g'), required)
          .replace(new RegExp('{' + name + '}', 'g'), input);
      return html;
    },
  }
};
</script>