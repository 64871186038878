<template id="imageType">
  <div class="field-type" v-on:click="addInput('imageType')" name="imageType">
    <i class="fa fa-image"></i> Image type
  </div>
</template>
<script>
export default {
  name: "imageType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="imageType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]"  pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "<i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Label</label>' +
        '   <input type="text" name="inputs[{index}][label]" placeholder="Label" class="form-control w-100" value="{label}" required/>' +
        "</div>",
      html:
        "" +
        '<div class="form-group">' +
        "   <label>{label}</label>" +
        '   <input type="file" name="{name}file" class="form-control" accept="image/*" onchange="uploadImage{name}(this);"/>' +
        '   <input type="hidden" name="{name}" value=""/>' +
        '   <div class="{name}image" style="position:relative;display: none">' +
        '       <img src="" class="{name}src" style="height: 50px"/>' +
        '       <a href="javascript:void(0)" onclick="removeImage{name}()" style="position: absolute">' +
        '           <i class="fa fa-close"></i>' +
        "       </a>" +
        "   </div>" +
        "</div>" +
        "<script>" +
        "   function uploadImage{name}(input) {" +
        "       let files = $(input)[0].files;" +
        "       let headers = {};" +
        "       if (typeof Storage !== 'undefined') {\n" +
        "         headers['X-AUTH-TOKEN'] = localStorage.getItem('token').replaceAll('\"', '');" +
        "      }" +
        "       if(files.length > 0 ){" +
        "           let data = new FormData();" +
        '           data.append("file", files[0]);' +
        '           data.append("widget", "froalaextras");' +
        '           data.append("field", "image");' +
        '           data.append("optimize", 0);' +
        "           $.ajax({" +
        '               url: "' + process.env.VUE_APP_CMS_BACKEND_BASE_URL + this.$apiList.uploadFileApi + '",' +
        '               type: "post",' +
        "               data: data," +
        '               dataType: "json",' +
        "               contentType: false," +
        "               processData: false," +
        '               headers: headers,' +
        "               success: function(response) { " +
        "                   let image = response.filePath;" +
        '                   $("[name={name}]").val(image);' +
        '                   $(".{name}src").attr("src", "' +
        process.env.VUE_APP_CMS_BACKEND_BASE_URL +
        '" + image);' +
        '                   $(".{name}image").show();' +
        "               }," +
        "           });" +
        '       } else { alert("Please select a file."); }' +
        "   }" +
        "   function removeImage{name}(){" +
        '       $("[name={name}]").val("");' +
        '       $(".{name}image").hide();' +
        "   }" +
        "<\\/script>",
      headers: {},
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "imageType", this.template);
    this.$emit("addgeneratedtemplate", "imageType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
