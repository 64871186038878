<template id="rawType">
  <div class="field-type" v-on:click="addInput('rawType')" name="rawType">
    <i class="fa fa-code"></i> Raw type
  </div>
</template>
<script>
export default {
  name: "rawType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="rawType" />' +
        '   <label class="required">Raw</label>' +
        '   <textarea name="inputs[{index}][raw]" placeholder="Raw" class="form-control w-100" ' +
        '       style="height: 300px;font-family: monospace;font-size: 12px;background: #0E2231; color: #fff">' +
        "{raw}" +
        "</textarea>" +
        '   <input type="hidden" name="inputs[{index}][name]" value="raw" /> ' +
        "</div>",
      html: "{{raw}}",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "rawType", this.template);
    this.$emit("addgeneratedtemplate", "rawType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
