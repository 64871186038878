<template id="linkType">
  <div class="field-type" v-on:click="addInput('linkType')" name="linkType">
    <i class="fa fa-link"></i> Link type
  </div>
</template>
<script>
export default {
  name: "linkType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="linkType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]"  pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "<i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">Label</label>' +
        '   <input type="text" name="inputs[{index}][label]" placeholder="Label" class="form-control w-100" value="{label}" required/>' +
        "</div>" +
        '<div class="checkbox">' +
        '   <label><input type="checkbox" name="inputs[{index}][required]" value="checked" {required}/> Required</label>' +
        "</div>" +
        "<hr>" +
        '<div class="form-group">' +
        "   <label>Group</label>" +
        '   <input type="text" name="inputs[{index}][group]" placeholder="Group" class="form-control w-100" value="{group}">' +
        "</div>",
      html:
        "" +
        '<div class="form-group">' +
        "   <label>{label}</label>" +
        '   <input type="text" name="{name}" class="form-control" placeholder="{label}" {required}>' +
        "</div>" +
        '<div class="form-group">' +
        "   <label>{label} Link</label>" +
        '   <input type="text" ' +
        '       name="url_{name}" ' +
        '       pattern="[Hh][Tt][Tt][Pp][Ss]?:\\/\\/(?:(?:[a-zA-Z\\u00a1-\\uffff0-9]+-?)*[a-zA-Z\\u00a1-\uffff0-9]+)(?:\\.(?:[a-zA-Z\\u00a1-\\uffff0-9]+-?)*[a-zA-Z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-zA-Z\\u00a1-\\uffff]{2,}))(?::\\d{2,5})?(?:\\/[^\\s]*)?" ' +
        '       class="form-control" ' +
        '       placeholder="{label} Link" {required}>' +
        "</div>",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "linkType", this.template);
    this.$emit("addgeneratedtemplate", "linkType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
