<template id="baseForm">
  <div class="row">
    <div class="col-md-6">
      <label>Input List</label>
      <div class="box-custom">
        <div class="box-body">
          <div id="inputList" class="inputList">
            <draggable class="list-group" :list="items" :group="'inputList'"
                       @change="sorting($event)" handle=".handle">
            <template v-for="(item, index) in items"  >
              <div class="inputList-item" :key="index">
                <div class="inputList-header">
                  <h3>{{ item.name }}</h3>
                  <i class="fa fa-close" v-on:click="removeInput(index)" ></i>
                  <i class="fa fa-minus" v-on:click="hideInput($event)" ></i>
                  <i class="fa fa-arrows handle"></i>
                </div>
                <div class="inputList-body" v-html="item.html">
                </div>
              </div>
            </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="padding-left: 20px">
      <label>Input Picker</label>
      <div class="box-custom">
        <div class="box-body">
          <baseType
              v-on:addinput="addInput"
              v-on:addtemplate="addTemplate"
              v-on:addgeneratedtemplate="addGeneratedTemplate">
          </baseType>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import baseType from "@/views/custom/FormBuilderTemplates/baseType";

export default {
  name: "adminFormBuilder",
  mixins: [],
  props: {
    // items: {
    //   type: [Array, Object]
    // }
  },
  data: function () {
    return {
      templates: [],
      items: [],
      htmls: [],
    }
  },
  components: {
    baseType,
    draggable
  },
  computed: {},
  methods: {
    sorting: function (evt) {
      if (evt.added != undefined) {
          this.$delete(this.items, Number(evt.added.newIndex) + 1);
      }
      if (evt.removed != undefined) {
        for (var c = (this.items.length); c > evt.removed.oldIndex; c--) {
          this.$set(this.items, c, this.items[c - 1]);
        }
        this.$set(this.items, evt.removed.oldIndex, '');
      }
    },
    capitalize(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    addInput(k, v, index) {
      console.log(this.items)
      console.log(this.items)
      let key = k.split(/(?=[A-Z])/).join(" ");
      key= this.capitalize(key);
      let html = this.templates[k];

      if (index === undefined) {
        index = this.items.length;
      }

      html = html.replace(new RegExp('{index}', 'g'), index);

      // SET INPUT VALUES
      if (v !== undefined) {
        Object.keys(v).forEach(name => {
          html = html.replace(new RegExp('{' + name + '}', 'g'), v[name]);
        });
      }

      // SET INPUT EMPTY VALUES
      html = html.replace(new RegExp('{[a-z].*?}', 'g'), '');


      let obj = {};
      obj = {name: key, html: html};
      this.items.splice(index, 0, obj);

      this.items.forEach((item, i) => {
        let x = item.html.replace(new RegExp('\\[[0-9].*?\\]', 'g'), '[' + i + ']');
        this.items[i].html = x;
      })
    },
    removeInput(index) {
      this.items.splice(index, 1);
    },
    hideInput(elem) {
      let inputItem = elem.target.closest(".inputList-item");
      let inputbody = inputItem.getElementsByClassName("inputList-body")[0];
      if (inputbody.style.display === "none") {
        inputbody.style.display = "block";
      } else {
        inputbody.style.display = "none";
      }
    },
    addTemplate(k, v) {
      this.templates[k] = v;
    },
    addGeneratedTemplate(k, v) {
      this.htmls[k] = v;
    }
  }
};
</script>