<template id="radioType">
  <div class="field-type" v-on:click="addInput('radioType')" name="radioType">
    <i class="fa fa-dot-circle-o"></i> Radio type
  </div>
</template>
<script>
export default {
  name: "radioType",
  mixins: [],
  props: {},
  data: function () {
    return {
      template:
        "" +
        '<input type="hidden" name="inputs[{index}][key]" value="radioType" />' +
        '<div class="form-group">' +
        '   <label class="required">Name</label>' +
        '   <input type="text" name="inputs[{index}][name]"  pattern="[a-zA-Z0-9_]+" placeholder="Name" class="form-control w-100" value="{name}" required/>' +
        "   <i>Letters, numbers and underscores only, no punctuation or special characters</i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">List of Labels</label>' +
        '   <input type="text" name="inputs[{index}][labels]" placeholder="Labels" class="form-control w-100" value="{labels}" required/>' +
        "   <i>List of radio button labels seperated by vertical bar </i>" +
        "</div>" +
        '<div class="form-group">' +
        '   <label class="required">List of Values</label>' +
        '   <input type="text" name="inputs[{index}][values]" placeholder="Values" class="form-control w-100" value="{values}" required/>' +
        "   <i>List of radio button values seperated by vertical bar </i>" +
        "</div>",
      html:
        "" +
        '<div class="form-group">' +
        '   <div class="radio">' +
        '       <label><input type="radio" name="{name}" value="{value}">{label}</label>' +
        "   </div>" +
        "</div>",
    };
  },
  mounted: function () {
    this.$emit("addtemplate", "radioType", this.template);
    this.$emit("addgeneratedtemplate", "radioType", this.html);
  },
  methods: {
    addInput(k, v) {
      console.log(v);
      this.$emit("addinput", k);
    },
  },
};
</script>
