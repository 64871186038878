<template id="baseType">
  <div id="inputPicker">
        <textType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate">
        </textType>
        <croppieType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></croppieType>
        <groupType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></groupType>
        <linkType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></linkType>
        <galleryType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></galleryType>
        <checkboxType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></checkboxType>
        <textareaType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></textareaType>
        <imageType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></imageType>
        <multiselectType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></multiselectType>
        <rawType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></rawType>
        <radioType
            v-on:addinput="addInput"
            v-on:addtemplate="addTemplate"
            v-on:addgeneratedtemplate="addGeneratedTemplate"></radioType>
  </div>
</template>
<script>
import textType from "@/views/custom/FormBuilderTemplates/textType";
import groupType from "@/views/custom/FormBuilderTemplates/groupType";
import linkType from "@/views/custom/FormBuilderTemplates/linkType";
import checkboxType from "@/views/custom/FormBuilderTemplates/checkboxType";
import radioType from "@/views/custom/FormBuilderTemplates/radioType";
import rawType from "@/views/custom/FormBuilderTemplates/rawType";
import multiselectType from "@/views/custom/FormBuilderTemplates/multiselectType";
import textareaType from "@/views/custom/FormBuilderTemplates/textareaType";
import imageType from "@/views/custom/FormBuilderTemplates/imageType";
import galleryType from "@/views/custom/FormBuilderTemplates/galleryType";
import croppieType from "@/views/custom/FormBuilderTemplates/croppieType";
export default {
  name: "baseType",
  mixins: [],
  props: {},
  data: function () {
    return {}
  },
  components: {
    textType,
    groupType,
    linkType,
    checkboxType,
    radioType,
    rawType,
    multiselectType,
    textareaType,
    imageType,
    galleryType,
    croppieType
  },
  computed: {},
  methods: {
    addInput(k, v) {
      this.$emit('addinput', k, v);
    },
    addTemplate(k, v) {
      this.$emit('addtemplate', k, v);
    },
    addGeneratedTemplate(k, v) {
      this.$emit('addgeneratedtemplate', k, v);
    }
  }
};
</script>